/**
 *  Plan mutations module file
 **/
import Vue from 'vue'
import { MUTATIONS } from './constants'
import { MODEL_PLAN, MODEL_ASSETS, MODEL_SUBPLAN_FILTERS } from './state'
import { getPlanFiltersFromStorage } from '@/helpers/plan.helper'
import { POIsRampColor, POIsOthersColor } from '@/layers/pois/pois-layer.helper'
import { areEquals } from '@/utils/arrayUtils.ts'

export const mutations = {
  [MUTATIONS.SAVE_PLAN]: (state, payload = {}) => {
    state.plan = payload
    state.plan_backup = { ...state.plan }

    const rawPoisColors = payload.poisColors ?? '{}'
    let poisColors = rawPoisColors
    if (typeof rawPoisColors === 'string') {
      poisColors = JSON.parse(rawPoisColors)
    }
    state.poisColors = poisColors
  },
  [MUTATIONS.SAVE_PLAN_FILTER]: (
    state,
    payload = { filters: [], updateDirtyFlags: true },
  ) => {
    // Replicate first subfilters to all filters: Proximity, Geographical distribution
    const payloadModified = payload.filters.map((item, index) => {
      if (index !== state.activeSubPlanFiltersIndex) {
        // proximity
        item.proximity = {
          ...payload.filters[state.activeSubPlanFiltersIndex].proximity,
        }
        // geographical distribution
        if (
          state.plan.filters[state.activeSubPlanFiltersIndex]
            .geographical_distribution
        ) {
          item.geographical_distribution = {
            ...payload.filters[state.activeSubPlanFiltersIndex]
              .geographical_distribution,
          }
        }
      }
      return item
    })
    // Set the new filters
    state.plan.filters = payloadModified
    // set custom geoboundaries to the plan
    const customGeoboundariesIds = payload.filters.reduce((acc, filter) => {
      const geoboundaries = filter.geoboundaries
      if (geoboundaries) {
        const region16Ids = geoboundaries.region_16?.map((r) => r.id) || []
        const region17Ids = geoboundaries.region_17?.map((r) => r.id) || []
        acc = [...acc, ...region16Ids, ...region17Ids]
      }
      return acc
    }, [])
    const uniqueCustomGeoboundaryIds = [...new Set(customGeoboundariesIds)]
    state.plan.custom_geoboundaries = uniqueCustomGeoboundaryIds || []
    // set custom pois to the plan
    const customPoiIds = payload.filters.reduce((acc, filter) => {
      const proximity = filter.proximity
      if (proximity && proximity.data) {
        const ids =
          proximity.data.filter((r) => r.type === 'custom').map((r) => r.id) ||
          []
        acc = [...acc, ...ids]
      }
      return acc
    }, [])
    const uniqueCustomPoiIds = [...new Set(customPoiIds)]
    state.plan.custom_pois = uniqueCustomPoiIds || []

    // Update dirty property
    state.plan.dirty = payload.updateDirtyFlags
      ? payload.updateDirtyFlags
      : true
    // Update plan backup
    state.plan_backup = { ...state.plan }
  },
  [MUTATIONS.MODIFY_PLAN_SUBFILTER_TABNAME]: (state, payload = {}) => {
    state.plan.filters = [...state.plan.filters].map((item, index) => {
      if (index === payload.id) {
        item.tabname = payload.tabname
      }
      return item
    })
    state.plan_backup = { ...state.plan }
  },
  [MUTATIONS.SET_PLAN_LOADING]: (state, payload) => {
    state.plan.loading = payload
  },
  [MUTATIONS.SET_GEOGRAPHICAL_DISTRIBUTION_REGIONS]: (state, payload) => {
    state.geographicalDistributionRegions = payload || []
  },
  [MUTATIONS.SET_IS_LOADING_GEOGRAPHICAL_DISTRIBUTION_REGIONS]: (
    state,
    payload,
  ) => {
    state.loadingGeographicalDistributionRegions = payload || false
  },
  [MUTATIONS.SET_ASSETS_LOADING]: (state, payload) => {
    state.assets.loading = payload
  },
  [MUTATIONS.SET_ASSETS_INITIAL_STATS]: (state, payload) => {
    const impressions = payload.allAssetsImpressions ?? -1
    if (!state.meta?.stats?.impressions) {
      state.assets.meta = {
        stats: { allAssetsImpressions: impressions, impressions },
      }
    } else {
      state.assets.meta.stats.allAssetsImpressions = impressions
      state.assets.meta.stats.impressions = impressions
      state.plan.impressions = impressions
    }
  },
  [MUTATIONS.SET_ASSETS_TOTAL_STATS]: (state, payload) => {
    state.assets.count = payload.count ?? -1
    if (!state.assets.meta.stats) {
      state.assets.meta.stats = {}
    }
    state.assets.meta.stats.impressions = payload.impressions ?? -1
    state.plan.impressions = payload.impressions ?? -1
  },
  [MUTATIONS.SET_SUBPLAN_FILTERS_INDEX]: (state, payload) => {
    state.activeSubPlanFiltersIndex = payload
  },
  [MUTATIONS.SET_DEFAULT_USER_COUNTRIES]: (state, payload) => {
    state.defaultUserCountries = payload
  },
  [MUTATIONS.ADD_SUBPLAN_FILTERS]: (state, payload) => {
    // add a new subplan filters
    state.plan.filters = [...state.plan.filters, MODEL_SUBPLAN_FILTERS()]
    // copy countries to the new subplan filters
    state.plan.filters[state.plan.filters.length - 1].countries = [
      ...state.plan.filters[0].countries,
    ]
    // copy proximity to the new subplan filters
    state.plan.filters[state.plan.filters.length - 1].proximity = {
      ...state.plan.filters[0].proximity,
    }
    // copy audience to the new subplan filters
    state.plan.filters[state.plan.filters.length - 1].delivery =
      state.plan.filters[0].delivery
    // copy geographical distribution to the new subplan filters
    if (state.plan.filters[0].geographical_distribution) {
      state.plan.filters[
        state.plan.filters.length - 1
      ].geographical_distribution = {
        ...state.plan.filters[0].geographical_distribution,
      }
    }
    // define active the new subplan filters
    state.activeSubPlanFiltersIndex = state.plan.filters.length - 1
    // Update dirty property
    state.plan.dirty = true
    // Update plan backup
    state.plan_backup = { ...state.plan }
  },
  [MUTATIONS.REMOVE_SUBPLAN_FILTERS]: (state, payload) => {
    if (state.plan.filters.length > 1) {
      const filters = [...state.plan.filters].filter(
        (item, index) => index !== payload,
      )
      state.plan.filters = [...filters]
      state.plan.dirty = true
      state.plan_backup = { ...state.plan }
    }
  },
  [MUTATIONS.SET_ASSETS]: (state, payload) => {
    state.assets = {
      count: payload.count,
      meta: payload.meta || state.assets.meta,
      data: payload.results || [],
      page: payload.page,
      page_size: payload.page_size,
      loading: false,
    }
  },
  [MUTATIONS.SET_ASSETS_EXCLUDED]: (state, payload) => {
    state.assetsExcluded = {
      count: payload.count,
      meta: payload.meta || state.assets.meta,
      data: payload.results || [],
      page: payload.page,
      page_size: payload.page_size,
    }
  },
  [MUTATIONS.RESET_PLAN]: (state) => {
    state.plan = MODEL_PLAN()
    state.assets = MODEL_ASSETS()
  },
  [MUTATIONS.SET_ASSETS_FINISHED_LOAD]: (state, payload) => {
    state.assetsFinishedLoad = payload
  },
  [MUTATIONS.SET_PLAN_BACKUP]: (state) => {
    state.plan = { ...state.plan_backup }
  },
  [MUTATIONS.SET_OLD_DIRTY_PLAN]: (state) => {
    const { filters, previousRoute } = getPlanFiltersFromStorage()
    if (
      previousRoute &&
      previousRoute.includes('/insights') &&
      previousRoute.includes('/panel') &&
      filters !== null
    ) {
      const preparedPlan = { ...state.plan }
      preparedPlan.filters = filters
      preparedPlan.dirty = true
      console.debug('🟪 Loading old dirty plan')
      state.plan = preparedPlan
    }
  },
  [MUTATIONS.SAVE_PLAN_AIDA_ID]: (state, aidaId) => {
    state.plan.aida_id = aidaId
  },
  [MUTATIONS.SET_VIEWSTATE]: (state, payload) => {
    state.viewState = payload
  },
  [MUTATIONS.SET_MAPUPDATEFLAGS]: (state, payload) => {
    state.mapUpdateFlags = { ...state.mapUpdateFlags, ...payload }
  },
  [MUTATIONS.SET_PROXIMITYFLAGS]: (state, { key, id, value }) => {
    Vue.set(state.proximityFlags[key], id, value)
  },
  [MUTATIONS.RESET_PROXIMITYFLAGS]: (state) => {
    state.proximityFlags = {
      open: {},
      selected: {},
      halfSelected: {},
      hidden: {},
    }
  },
  [MUTATIONS.REMOVE_CUSTOM_GEOMETRY]: (state) => {
    state.fixme = null
  },
  [MUTATIONS.SET_OPEN_GEOMETRY]: (state, id) => {
    state.openCustomGeometry = id
  },
  [MUTATIONS.SET_NEW_POI_DATASET_FORM]: (state, payload) => {
    state.newPoiDatasetForm = {
      ...state.newPoiDatasetForm,
      ...payload,
    }
  },
  [MUTATIONS.SET_DISTRIBUTION]: (state, payload = {}) => {
    state.distribution = payload
  },
  [MUTATIONS.SET_AUDIENCE_DELIVERY]: (state, delivery) => {
    // state.plan.filters[0].delivery = delivery
    state.latestdelivery = delivery
  },
  [MUTATIONS.UPDATE_PROXIMITY_DATA]: (state, payload) => {
    state.plan.proximity = payload
  },
  [MUTATIONS.UPDATE_LEGEND_FILTER]: (state, payload) => {
    state.plan.legend = {
      ...state.plan.legend,
      ...payload,
    }
  },
  [MUTATIONS.SET_EXCLUDED_ASSET_LAYER_AVAILABLE]: (state, payload) => {
    state.plan.legend.canAssetsExcludedLayerBeShown = payload
  },
  [MUTATIONS.SET_ASSETS_DETAILS]: (state, payload) => {
    state.assetsDetails = payload
  },
  [MUTATIONS.SET_ASSETS_READY_WITH_ALL_PROPERTIES]: (state, payload) => {
    state.assetsReadyWithAllProperties = payload.ready
  },
  [MUTATIONS.SET_PACKAGES_DATA]: (state, payload) => {
    state.packages = payload
  },
  [MUTATIONS.SET_PACKAGE]: (state, payload) => {
    state.plan.package = payload
    state.plan.filters[[state.activeSubPlanFiltersIndex]].package = payload.id
    state.plan.dirty = true
  },
  [MUTATIONS.SET_PACKAGES_PRICE_RANGE]: (state, payload) => {
    state.plan.package.filter.priceRange = payload
    state.plan.dirty = true
  },
  [MUTATIONS.UPDATE_PACKAGES_FILTER]: (state, payload) => {
    state.plan.package.filter = { ...state.plan.package.filter, ...payload }
    state.plan.dirty = true
  },
  [MUTATIONS.SET_PACKAGES_DATE_RANGE]: (state, payload) => {
    state.plan.package.filter.dateRange = payload
    state.plan.dirty = true
  },
  [MUTATIONS.SET_ASSETS_FACING_ORIENTATION_ICONS]: (state, payload) => {
    state.plan.legend.facingOrientationIcons = payload
  },
  [MUTATIONS.SET_POIS_BUFFER_VISIBILITY]: (state, payload) => {
    state.plan.legend.poisBufferVisibility = payload
  },
  [MUTATIONS.SET_POIS_VISIBILITY]: (state, payload) => {
    state.plan.legend.poisVisibility = payload
  },
  [MUTATIONS.SET_BOUNDARIES_VISIBILITY]: (state, payload) => {
    state.plan.legend.boundariesVisibility = payload
  },
  [MUTATIONS.SET_ASSETS_IDS_LAYER_VISIBILITY]: (state, payload) => {
    state.plan.legend.assetsIdsLayerVisibility = payload
  },
  [MUTATIONS.SET_COLORIZE_ASSETS]: (state, payload) => {
    state.plan.legend.colorizeAssets = payload
  },
  [MUTATIONS.SET_BASEMAP]: (state, payload) => {
    state.plan.basemap = payload
  },
  [MUTATIONS.SET_PACKAGE_PRICE]: (state, payload) => {
    state.plan.package.price = payload
    state.plan.dirty = true
  },
  [MUTATIONS.SET_PACKAGE_STATE]: (state, payload) => {
    state.plan.package.state = payload
  },
  [MUTATIONS.SET_FORCE_LOGOUT]: (state, payload) => {
    state.forceLogout = payload
  },
  [MUTATIONS.SET_PACKAGE_AVAILABILITY]: (state, payload) => {
    state.plan.package.availability = payload
  },
  [MUTATIONS.SET_HIGHLIGHTED_ASSETS]: (state, payload) => {
    state.highlightedAssets = payload
  },
  [MUTATIONS.SET_POI_FREE_COLOR]: (state, payload) => {
    const poisColors = { ...state.poisColors }
    const colorForPOI = poisColors[payload]
    if (colorForPOI === undefined) {
      let assignedColor = POIsOthersColor
      const assignedColors = Object.values(
        JSON.parse(JSON.stringify(state.poisColors)),
      )
      const availableColors = POIsRampColor.filter((color) => {
        return !assignedColors.find((assignedColorsElement) =>
          areEquals(assignedColorsElement, color),
        )
      })
      if (availableColors.length > 0) {
        assignedColor = availableColors[0]
      }
      poisColors[payload] = assignedColor
      state.poisColors = poisColors
    }
  },
  [MUTATIONS.CLEAR_POI_COLOR]: (state, payload) => {
    const poisColors = { ...state.poisColors }
    const colorToBeRemoved = poisColors[payload]
    if (colorToBeRemoved === undefined) {
      throw new Error(`POI ${payload} has no color associated`)
    }

    delete poisColors[payload]
    state.poisColors = poisColors
  },
  [MUTATIONS.CLEAR_POIS_COLOR]: (state) => {
    state.poisColors = {}
  },
  [MUTATIONS.SET_PLAN_DIRTY]: (state) => {
    state.plan.dirty = true
  },
  [MUTATIONS.SET_TRANSITION_BETWEEN_BASEMAPS]: (state, payload) => {
    state.transitionBetweenBaseMaps = payload
  },
  [MUTATIONS.SET_ASSETS_ANGLE_NORTH_MAP]: (state, payload) => {
    state.plan.legend.angleNorthMap = payload
  },
  [MUTATIONS.ADD_GEOBOUNDARY_GEOMETRY_TO_CACHE]: (state, payload) => {
    const found = state.geoBoundariesGeometriesCache.find(
      (element) =>
        element.id === payload.id && element.type_id === payload.type_id,
    )
    if (found) return
    if (state.geoBoundariesGeometriesCache.length < 20) {
      state.geoBoundariesGeometriesCache.push(payload)
    } else {
      state.geoBoundariesGeometriesCache.shift()
      state.geoBoundariesGeometriesCache.push(payload)
    }
    console.debug(
      '💾 Cache geoboundaries geometries',
      state.geoBoundariesGeometriesCache.map((g) => `${g.id} (${g.type_id})`),
    )
  },
}
